import React from "react";
import Layout from "../components/Layout";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import Seo from "../components/Seo";

const Blog = styled.section`
  margin: 2rem auto 3rem auto;
  padding: 0 1rem;
  max-width: 50rem;

  .category {
    display: block;
    color: #00605e;
    font-weight: 700;
    font-size: 0.9rem;
    text-transform: uppercase;
  }

  h1 {
    font-size: 2.5rem;
    margin-bottom: 0;
    margin: 0 auto;

    @media only screen and (max-width: 65rem) {
      font-size: 1.8rem;
    }
  }

  h2 {
    margin: 1rem auto;
    font-weight: 600;
    font-size: 1.2rem;
    color: #045757;
  }
`;

const Content = styled.article`
  margin: 0 auto 1rem auto;
  max-width: 50rem;

  p,
  ol,
  ul,
  li {
    font-size: 1.2rem;
    line-height: 2rem;
    color: #2b3138;
  }

  figcaption {
    font-size: 0.8rem;
    margin: 0.5rem auto 3rem auto;
    text-align: center;
    color: #555;
  }
`;

const BlogImage = styled.div`
  padding: 0 1rem;
  .gatsby-image-wrapper {
    display: block;
    max-width: 70rem;
    margin: 7rem auto 0rem auto;
    object-fit: cover;
    height: 30rem;
    border-radius: 5px;
  }

  @media screen and (max-width: 900px) {
    .gatsby-image-wrapper {
      height: 20rem;
    }
  }
`;

const DateOfPost = styled.time`
  display: block;  
  margin-top: 1rem;
  margin-bottom: 4rem;
  font-weight: 600;
  font-size: 1.2rem;
  `

export default function Template({ data }) {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;
  const title = frontmatter.title;
  const date = frontmatter.date;
  const alt = frontmatter.imageAlt;
  const featuredImage = frontmatter.featuredImage;
  const excerpt = markdownRemark.excerpt;
  return (
    <Layout>
      <Seo title={title} description={excerpt} image={featuredImage} />
      <BlogImage className="gatsby-image-wrapper">
        <GatsbyImage image={getImage(featuredImage)} alt={alt} />
      </BlogImage>
      <Blog>
        <h1>{title}</h1>
        <DateOfPost>{date}</DateOfPost>
        <Content dangerouslySetInnerHTML={{ __html: html }} />
      </Blog>
    </Layout>
  );
}
export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      excerpt(format: PLAIN)
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        imageAlt
        featuredImage {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, PNG])
          }
        }
      }
    }
  }
`;
